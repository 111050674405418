import React, { useEffect } from 'react';
import { graphql } from 'gatsby';

import Layout from "../components/layout"
import SEO from "../components/seo"
// import DemoButtonContainer from "../components/demo-button-container";

import "./articlePage.scss";

const nl2br = (str, is_xhtml) => {
    if (typeof str === 'undefined' || str === null) {
        return '';
    }
    const breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2').replace(/&gt;/g, '>').replace(/&lt;/g, '<');
}

const ArticlePage = (queryData) => {
    const article = queryData.data.gcms.blogArticles[0];
    const titles = queryData.data.gcms.titles;

    let title = '';
    let metaDescription = '';
    let content = '';
    let slug = '';

    let nieuwsbriefTitle = '';
    let nieuwsbriefSubtitle = '';

    if (article != null) {
        if (article.blogTitle != null) {
            title = article.blogTitle;
        }
        if (article.blogMetaDescription != null) {
            metaDescription = article.blogMetaDescription;
        }
        if (article.blogContent != null && article.blogContent.html != null) {
            content = nl2br(article.blogContent.html);
            content = content.replace(/<p><img/g, '<img').replace(/><\/p>/g, ' />');
        }
        if (article.slug != null) {
            slug = article.slug;
        }
    }

    if (titles.length > 0) {
        for (const title of titles) {
            if (title.indicator === 'nieuwsbrief-title' && title.value) {
                nieuwsbriefTitle = title.value;
            }
            if (title.indicator === 'nieuwsbrief-subtitle' && title.value) {
                nieuwsbriefSubtitle = title.value;
            }
        }
    }


    useEffect(() => {
        if (typeof window !== "undefined") {
            setTimeout(() => {
                window.scrollTo(0, 0);
            }, 200);
        }
    });

    return (
        <Layout>
            <SEO title={title} description={metaDescription} urlParams={slug} />
            <img className="print print-image" src='https://media.travelspirit.nl/original/sm/TravelSpirit_Logo-FC.png' alt="logo" loading="lazy" />
            <div className="modules-container container">
                <div className="inner-container">
                    <div className={slug === 'in-memoriam-sander' ? "sander html-text inline" : "html-text inline"} dangerouslySetInnerHTML={{ __html: content }} />
                </div>
            </div>
            {/* <div className="modules-demo-container">
                <DemoButtonContainer titles={titles} />
            </div> */}
            <div className="form-container">
                <h2>{nieuwsbriefTitle}</h2>
                <p>{nieuwsbriefSubtitle}</p>
                {/* <div id='mpform1102' ></div> */}
                <form id="form1102" encType="application/x-www-form-urlencoded" className="mpForm" method="post" action="https://m13.mailplus.nl/genericservice/code/servlet/Redirect">
                    <ul className="mpFormTable mpTwoColumnLayout">
                        <li id="CNT1156" role="group" className="mpQuestionTable">
                            <div className="mpFormLabel">
                                <label className="descriptionLabel" htmlFor="field1156" id="lbl-field1156">E-mailadres</label>
                                <span className="mandatorySign"> *</span>
                            </div>
                            <div className="mpFormField">
                                <input type="text" id="field1156" name="field1156"></input>
                                <div className="sublabel">Vul hier je e-mailadres in naam@bedrijf.nl</div>
                            </div>
                        </li>
                        <li id="CNT1162" role="group" className="mpQuestionTable">
                            <div className="mpLabelRow">
                                <div className="submitCellSpacer">
                                    <span></span>
                                </div>
                                <div className="submitCell">
                                    <input value="Verzenden" className="submitButton" name="next" type="submit" id="field1162"></input>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <fieldset style={{ display: 'none' }}>
                        <input type="hidden" name="userId" value="2110488"></input>
                        <input type="hidden" name="formEncId" value="RpPvABh6UMD4wG9y9VHG"></input>
                        <input type="hidden" name="pagePosition" value="1"></input>
                        <input type="hidden" name="viewMode" value="STATICINTEGRATION"></input>
                        <input type="hidden" name="redir" value="formAdmin2"></input>
                        <input type="hidden" name="formLayout" value="N"></input>
                        <input type="hidden" name="errorText" value="Dit formulier kon niet verzonden worden om de volgende reden(en):"></input>
                    </fieldset>
                </form>
            </div>
        </Layout>
    )
}

export default ArticlePage

export const pageQuery = graphql`
query ArticlePageQuery($id: ID) {
    gcms {
        blogArticles(where: { id: $id }) {
            blogTitle
            blogMetaDescription
            blogContent { html }
            publishDateTime
            readTimeMinutes
            slug
        }
        titles {
            indicator
            value
            richValue { html }
        }
    }
}`;